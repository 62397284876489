<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Laporan Periodik
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mt-2"
        >
          <label>Tahun</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="tahun"
            :options="tahunOption.options"
          />
        </b-col>
         <b-col
            cols="12"
            md="6"
            class="mb-md-0" style="margin-top: 25px;"
          >
            <b-button
              variant="primary"
              @click="download()"
              style="margin-top: 20px;"
            >
              <span class="text-nowrap">Download Excel</span>
            </b-button>
          </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import {
  exportLaporanPeriodik,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormInput,
    BButton,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tahun: new Date().getFullYear(),
      tahunOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tahun --' },
        ],
      },
    }
  },
  mounted() {
    this.yearOptions()
  },
  methods: {
    yearOptions() {
      const currentYear = new Date().getFullYear();
      const startYear = 1990;

      // Generate options for the select dropdown from the current year to 25 years ago
      for (let year = currentYear; year >= startYear; year--) {
        this.tahunOption.options.push({ value: year, label: year });
      }
    },
    download() {
     exportLaporanPeriodik(this.tahun)
      .then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Laporan Periodik.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .catch(function(error) {
        // Error callback function
        // You can handle errors here
      });

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
